import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/packing.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
    <Badge
        mr={3}
        mb={3}
        bg="#eae9f2"
        color="#696871"
        fontSize="16px"
        px={3}
        py={2}
    >
        {children}
    </Badge>
);


const BlogDetails = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="hero">
                                    Why parcel forwarding is essential for your small business
                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>Nov 7, 2020
                  </Text>
                                    <Text>Michael Tippett
                  </Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="pb-0 pt-4">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5">
                                {/* <!-- Blog section --> */}
                                <Post>
                                    <div>
                                        <img src={titleImage} alt="Australia Products" className={styles.titleImage} />
                                    </div>

                                    <p>When running a small business, you want to make sure that all your products reach your customers. You can now do this within your budget and convenience, thanks to parcel forwarding. It also brings in a lot of lucrative business benefits that can help you establish your good reputation and sustain growth in your niche.</p>



                                    <p>But what exactly can you expect from parcel forwarding? This article will discuss the benefits it brings to your business. Take this as an opportunity to re-imagine your current small business operations and find viable yet cost-effective means to grow.</p>



                                    <ol type="1"><li><strong>Professionalism and Convenience</strong></li></ol>



                                    <p>One of the most vital aspects of running a small business is staying committed to your customers and being flexible to their needs. With parcel forwarding in your business, you can put that professionalism to practice and offer high-quality, convenient services.</p>



                                    <p>Parcel forwarding is a noble, customer-oriented effort that is worth highlighting and can let you gain you more engagement and orders over time. For example, let’s say you have a customer that may want to purchase something from your business in Australia. However, their job requires them to go around Southeast Asia.</p>



                                    <p>As a viable solution, parcel forwarding enables you to provide customers with convenient access to your business’s services. Doing so sustains your professional connections with your customers, even if they move around temporarily or relocate permanently. It creates a healthy customer base that stays committed to your business and anticipates your expansion.</p>



                                    <ol type="1" start="2"><li><strong>Data Protection and Organization</strong></li></ol>



                                    <p>Modern business owners like you should be aware of the security risks of your business, such as with parcels falling into the wrong hands. Thankfully, parcel forwarding has commendable data protection in mind, as our professional team in HotSnail can offer you and your customers the liberty to choose what can be opened or remain sealed. Through this, you become a valuable asset to your customers’ best interests and safety.</p>



                                    <p>Parcel forwarding also enables convenient sorting of scanned documents and letters. It correlates with data protection since you can effectively sort your parcels in customised folders and mail it electronically to your customers. You can also organise your customers’ parcels electronically for ease in processing and speed up your services.</p>



                                    <p>For other data privacy and other related security recommendations, we at HotSnail can also talk you through our secure process to enable you, as a small business owner, to keep your commitment with your customers.</p>



                                    <ol type="1" start="3"><li><strong>Globalising your business operations</strong></li></ol>



                                    <p>When you think about your small business’s growth and expansion, you may think that global reach is something that comes along further down the line because it may seem costly and a logistical nightmare to expand worldwide. But through parcel forwarding, you can make worldwide services possible and compete against other businesses effectively.</p>



                                    <p>For instance, let’s say your small business will invest in parcel-based marketing by sending out business newsletters and promotional materials to entice customers. Parcel forwarding allows you to do that with reasonable pricing and convenience. It grants you the flexibility to set your business within the global frame for maximised scalability.</p>



                                    <p><strong>Conclusion</strong></p>



                                    <p>Parcel forwarding is a great way for your small business to upgrade your current customer acquisition and engagement. It allows you to grow viably worldwide without the hassle. Consider the previously mentioned tips and invest in parcel forwarding today.</p>



                                    <p>In need of <a href="http://hotsnail.com.au/">high-quality parcel forwarding for your small business in Australia</a>? Partner with us today at HotSnail. We can provide you and your customers with convenient mail redirection to meet their needs. Talk to us today about our parcel forwarding packages to upgrade your small business.</p>



                                    <p>Get your snail mail anywhere in the world. Access your mail just like email. Shop in Australia and forward your parcels worldwide</p>


                                </Post>
                                <Box className="d-flex" mt={4}>
                                    <BadgePost>Business</BadgePost>
                                    <BadgePost>Parcel forwarding</BadgePost>
                                    <BadgePost>Mail Scanning</BadgePost>
                                </Box>

                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default BlogDetails;
